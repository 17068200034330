import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '@/pages/Dashboard'
import NProgress from 'nprogress/nprogress'
import { LS_TOKEN_KEY } from '@/constants'
import store from '@/store'

NProgress.configure({ showSpinner: false })

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter (routeTo, routeFrom, next) {
      store.dispatch('entities/fetchEntities')
        .then(() => next())
        .catch(() => next(new Error('failed loading entities')))
    }
  },
  {
    path: '/401',
    name: '401',
    component: require('../pages/_401').default,
    props: true
  },
  {
    path: '/404',
    name: '404',
    component: require('../pages/_404').default,
    props: true
  },
  {
    path: '/*',
    redirect: '404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (routeTo, routeFrom, next) => {
  NProgress.start()

  if (!routeFrom.name && !['401', '404'].includes(routeTo.name)) {
    if (!routeTo.query.token) {
      return next('/401')
    }

    sessionStorage.setItem(LS_TOKEN_KEY, routeTo.query.token)
  }

  next()
})

router.afterEach((routeTo, routeFrom) => {
  NProgress.done()
})

router.onError(e => {
  NProgress.done()
})

export default router
