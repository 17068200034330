<template>
  <transition
    enter-active-class="ease-out duration-300"
    enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enter-to-class="opacity-100 translate-y-0 sm:scale-100"
    leave-active-class="ease-in duration-300"
    leave-from-class="opacity-100 translate-y-0 sm:scale-100"
    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    appear
  >
    <div v-if="isOpen" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
        <div @click="close" class="absolute inset-0"></div>

        <div
          class="inline-block bg-white border-2 border-gray-300 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',
  data () {
    return {
      isOpen: false,
      escHandler: null
    }
  },
  emits: ['open', 'opened', 'close', 'closed'],
  methods: {
    open () {
      this.$emit('open')
      this.isOpen = true

      setTimeout(() => {
        this.$emit('opened')
      }, 300)
    },
    close () {
      this.isOpen = false
      this.$emit('close')

      setTimeout(() => {
        this.$emit('closed')
      }, 200)
    }
  },
  created () {
    this.escHandler = (e) => {
      if (this.isOpen && e.keyCode === 27) {
        this.close()
      }
    }

    document.addEventListener('keydown', this.escHandler)
  },
  beforeUnmount () {
    document.removeEventListener('keydown', this.escHandler)
  }
}
</script>
