import Vuex from 'vuex'

import modules from './modules'

const store = Vuex.createStore({
  modules,
  actions: {
    reset ({ commit }) {
      // resets state of all the modules
      Object.keys(modules).forEach(moduleName => {
        commit(`${moduleName}/RESET`)
      })
    }
  },
  // strict: process.env.NODE_ENV !== 'production'
  strict: false
})

export default store
