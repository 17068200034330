import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './sentry'
import 'nprogress/nprogress.css'
import './style.css'
import globalComponents from './components/_global'

const app = createApp(App)
  .use(router)
  .use(store)
  .use(globalComponents)

app.mount('#app')
