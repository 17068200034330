import http from '@/utils/http'
import { getToken } from '@/utils/helpers'

const initialState = () => ({
  list: []
})

export const state = initialState()

export const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_LIST (state, list) {
    state.list = list
  }
}

export const getters = {
  list: state => (filter, sortBy) => {
    let items = state.list

    if (sortBy) {
      const [col, currentDirection] = sortBy.split('|')
      const returnVal = currentDirection === 'asc' ? [1, -1] : [-1, 1]

      items = items.sort((a, b) => {
        if (a[col] === b[col]) {
          return 0
        }

        if (!a[col]) {
          return 1
        }

        if (!b[col]) {
          return -1
        }

        return (a[col] > b[col]) ? returnVal[0] : returnVal[1]
      })
    }

    if ({}.hasOwnProperty.call(filter, 'term') && filter.term) {
      const term = filter.term.toLowerCase()
      items = items.filter(item => item.id.toLowerCase().includes(term) || item.name.toLowerCase().includes(term))
    }

    if ({}.hasOwnProperty.call(filter, 'integrations') && filter.integrations.length) {
      items = items.filter(item => item.integrations.some(integration => filter.integrations.includes(integration)))
    }

    if ({}.hasOwnProperty.call(filter, 'country') && filter.country) {
      items = items.filter(item => item.country === filter.country)
    }

    return items
  },
  divisionIds: state => state.list.map(item => item.id)
}

export const actions = {
  fetchEntities ({ commit }) {
    return http({
      url: `${process.env.VUE_APP_API_URL}entities`,
      headers: {
        'x-coretix-token': getToken()
      }
    }).then(entities => {
      commit('SET_LIST', entities)

      return entities
    })
  }
}
