<template>
  <div>
    <label :for="(forId || `form-item--${prop}`)" class="block text-sm font-medium text-gray-700" :class="{'text-red-500': error.length}">{{ label }}</label>
    <div class="mt-1 rounded-md shadow-sm relative">
      <slot></slot>

      <div v-if="error.length" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <div v-if="error.length" class="absolute">
      <p class="mt-1 text-xs text-red-500">{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFormItem',
  props: {
    forId: {
      type: String
    },
    prop: {
      type: String
    },
    label: String,
    error: {
      type: String,
      default: ''
    }
  }
}
</script>
