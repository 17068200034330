<template>
  <form @submit.prevent="submit">
    <h3 v-if="isEdit" class="text-lg leading-6 font-medium text-gray-900 mb-4">Edit entity</h3>
    <h3 v-else class="text-lg leading-6 font-medium text-gray-900 mb-4">Add new entity</h3>

    <div class="mt-6 grid grid-cols-1 gap-6">

      <div class="z-0 flex flex-wrap shadow-sm">
        <BaseCheckbox :checked="isIntegrationUsed('adyen')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'adyen')">
          Adyen
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('cornerstone')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'cornerstone')">
          Cornerstone
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('apicBase')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'apicBase')">
          ApicBase
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('bc365')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'bc365')">
          BC365 - Proquro - Tagetik
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('vectron')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'vectron')">
          Vectron
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('nexudus')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'nexudus')">
          Nexudus
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('chromeriver')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'chromeriver')">
          Chromeriver
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('fis')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'fis')">
          FIS
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('apaleo')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'apaleo')">
          Apaleo
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('hfc')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'hfc')">
          HFC
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('procore')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'procore')">
          Procore
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('recurly')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'recurly')">
          Recurly
        </BaseCheckbox>

        <BaseCheckbox :checked="isIntegrationUsed('tradeinterop')" @input="$store.commit('entity/SET_USED_INTEGRATION', 'tradeinterop')">
          TradeInterop
        </BaseCheckbox>
      </div>

      <div>
        <BaseFormItem label="Entity name" prop="id" :error="errors.id">
          <BaseInput v-model="id" @input="validate('id')" name="id" />
        </BaseFormItem>
      </div>

      <div>
        <BaseFormItem
          label="Country"
          prop="country"
          :error="errors.country"
        >
          <CountrySelect v-model="country" />
        </BaseFormItem>
      </div>

      <div v-if="isIntegrationUsed('procore')">
        <BaseFormItem label="Procore project ID" prop="procoreProjectId" :error="errors.procoreProjectId">
          <BaseInput v-model="procoreProjectId" @input="validate('procoreProjectId')" name="procoreProjectId" />
        </BaseFormItem>
      </div>

      <div v-if="isIntegrationUsed('apaleo') || isIntegrationUsed('hfc')">
        <BaseFormItem label="Hotel Abbreviation" prop="amadeusPropertyName" :error="errors.amadeusPropertyName">
          <BaseInput v-model="amadeusPropertyName" @input="validate('amadeusPropertyName')" name="amadeusPropertyName" />
        </BaseFormItem>
      </div>

      <div>
        <fieldset v-if="isIntegrationUsed('adyen')" class="border border-gray-200 p-2 rounded-md">
          <legend class="text-sm font-medium leading-5 text-gray-700 px-2">Adyen</legend>

          <div class="grid grid-cols-1 gap-6">
            <AdyenMerchantAccountCodesInput v-model="adyenMerchantAccountCodes" :error="errors.adyenMerchantAccountCodes" />
          </div>
        </fieldset>

        <fieldset v-if="isIntegrationUsed('cornerstone')" class="border border-gray-200 p-2 rounded-md">
          <legend class="text-sm font-medium leading-5 text-gray-700 px-2">Cornerstone</legend>

          <div class="grid grid-cols-1 gap-6">
            <BaseFormItem label="Property name" prop="cornerstoneCompanyName" :error="errors.cornerstoneCompanyName">
              <BaseInput v-model="cornerstoneCompanyName" @input="validate('cornerstoneCompanyName')" name="cornerstoneCompanyName" />
            </BaseFormItem>
          </div>
        </fieldset>

        <fieldset v-if="isIntegrationUsed('apicBase')" class="border border-gray-200 p-2 rounded-md">
          <legend class="text-sm font-medium leading-5 text-gray-700 px-2">ApicBase</legend>

          <div>
            <BaseFormItem label="Library" prop="apicBaseLibraryId" :error="errors.apicBaseLibraryId">
              <ApicBaseLibrarySelect :apicBaseLibraries="apicBaseLibraries" v-model="apicBaseLibraryId" />
            </BaseFormItem>

            <BaseFormItem label="Outlet name" prop="apicBaseOutletName" :error="errors.apicBaseOutletName">
              <BaseInput v-model="apicBaseOutletName" @input="validate('apicBaseOutletName')" name="apicBaseOutletName" />
            </BaseFormItem>
          </div>
        </fieldset>
      </div>
    </div>

    <fieldset v-if="isIntegrationUsed('nexudus')" class="border border-gray-200 p-2 rounded-md">
          <legend class="text-sm font-medium leading-5 text-gray-700 px-2">Nexudus</legend>

          <div class="grid grid-cols-1 gap-6">
            <BaseFormItem label="Property name" prop="nexudusBusinessName" :error="errors.nexudusBusinessName">
              <BaseInput v-model="nexudusBusinessName" @input="validate('nexudusBusinessName')" name="nexudusBusinessName" />
            </BaseFormItem>
          </div>
        </fieldset>

    <div class="mt-8 border-t border-gray-200 pt-5">
      <div class="flex justify-end">
        <span class="inline-flex rounded-md shadow-sm">
          <button
            @click="$emit('cancel')"
            type="button"
            class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
          >
            Cancel
          </button>
        </span>
        <span class="ml-3 inline-flex rounded-md shadow-sm">
          <button
            type="submit"
            class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-500 focus:outline-none transition duration-150 ease-in-out disabled:opacity-75"
            :disabled="isLoading"
          >
            Save
          </button>
        </span>
      </div>
    </div>
  </form>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { ENDPOINTS } from '@/constants'
import NProgress from 'nprogress/nprogress'
import AdyenMerchantAccountCodesInput from '@/components/adyen/AdyenMerchantAccountCodesInput'
import CountrySelect from '@/components/page-components/CountrySelect'
import ApicBaseLibrarySelect from '@/components/page-components/ApicBaseLibrarySelect'

const { mapFields } = createHelpers({
  getterType: 'getItemField',
  mutationType: 'updateItemField'
})

const errorMessages = {
  endpoint: { required: 'Please select BC365 environment for new entity' },
  id: {
    required: 'Please enter ID',
    unique: 'A entity with this ID already exists'
  },
  name: {
    required: 'Please enter BC365 entity name',
    exists: 'Entity name was not found in the selected environment'
  },
  country: { required: 'Please enter country code' },
  adyenMerchantAccountCodes: { required: 'Please enter Adyen merchant account code' },
  cornerstoneCompanyName: { required: 'Please enter Cornerstone company name' },
  apicBaseOutletName: { required: 'Please enter ApicBase outlet name' },
  nexudusBusinessName: { required: 'Please enter Nexudus business name' },
  apicBaseLibraryId: { required: 'Please select ApicBase library' }
}

export default {
  name: 'EntityForm',
  components: { CountrySelect, AdyenMerchantAccountCodesInput, ApicBaseLibrarySelect },
  data () {
    return {
      endpoints: ENDPOINTS,
      isLoading: false
    }
  },
  computed: {
    ...mapFields('entity', [
      'endpoint',
      'id',
      'name',
      'country',
      'adyenMerchantAccountCodes',
      'amadeusPropertyName',
      'cornerstoneCompanyName',
      'apicBaseOutletName',
      'nexudusBusinessName',
      'apicBaseLibraries',
      'apicBaseLibraryId',
      'procoreProjectId'
    ]),
    divisionIds () {
      return this.$store.getters['entities/divisionIds']
    },
    isEdit () {
      return !!this.$store.getters['entity/originalItem'].id
    },
    originalItem () {
      return this.$store.getters['entity/originalItem']
    },
    errors () {
      return this.$store.getters['entity/errors']
    }
  },
  methods: {
    isIntegrationUsed (integration) {
      return this.$store.getters['entity/usedIntegrations'].includes(integration)
    },
    validateId () {
      if (!this.id) {
        return errorMessages.id.required
      }

      if (this.divisionIds.includes(this.id)) {
        if (this.isEdit) {
          return this.originalItem.id === this.id ? '' : errorMessages.id.unique
        }

        return errorMessages.id.unique
      }

      return null
    },
    async validate (field = null) {
      if (!field) {
        const errors = {}

        const idErr = this.validateId()
        if (idErr) {
          errors.id = idErr
        }

        if (!this.country) {
          errors.country = errorMessages.country.required
        }

        if (this.isIntegrationUsed('adyen')) {
          if (!this.adyenMerchantAccountCodes.length || !this.adyenMerchantAccountCodes[0]) {
            errors.adyenMerchantAccountCodes = errorMessages.adyenMerchantAccountCodes.required
          }
        }

        if (this.isIntegrationUsed('nexudus')) {
          if (!this.nexudusBusinessName) {
            errors.nexudusBusinessName = errorMessages.nexudusBusinessName.required
          }
        }

        this.$store.commit('entity/SET_ERRORS', errors)

        const hasErrors = Object.keys(errors).length

        if (hasErrors) {
          this.$store.dispatch('notifications/showNotification', {
            message: 'Please fill in correct information',
            type: 'error'
          })
        }

        return !hasErrors
      } else {
        const errors = { ...this.errors }

        if (field === 'id') {
          const idErr = this.validateId()
          if (idErr) {
            errors[field] = idErr
          } else {
            delete errors[field]
          }
        } else {
          if (!this[field]) {
            errors[field] = errorMessages[field].required
          } else {
            delete errors[field]
          }
        }

        this.$store.commit('entity/SET_ERRORS', errors)
      }
    },
    async submit () {
      this.isLoading = true
      NProgress.start()

      if (await this.validate()) {
        try {
          await this.$store.dispatch('entity/saveEntity')

          this.$emit('saved', this.item)

          this.$store.dispatch('notifications/showNotification', {
            message: 'Entity saved'
          })
        } catch (e) {
          this.$store.dispatch('notifications/showNotification', {
            message: e.response.data.message || 'Error saving entity',
            type: 'error'
          })
        }
      }

      NProgress.done()
      this.isLoading = false
    }
  }
}
</script>
