export const LS_TOKEN_KEY = 'ctx-token'

export const MAIN_SYSTEMS = {
  bc365: 'BC365'
}

export const INTEGRATIONS = {
  proquro: 'ProQuro',
  adyen: 'Adyen',
  amadeus: 'Amadeus',
  cornerstone: 'Cornerstone',
  apicBase: 'Apicbase'
}

export const ENDPOINTS = [{
  label: 'NAV',
  value: 'NAV'
}, {
  label: 'NAV US',
  value: 'NAV US'
}, {
  label: 'BDO FR NAV',
  value: 'BDO FR NAV'
}, {
  label: 'BDO US NAV',
  value: 'BDO US NAV'
}]
