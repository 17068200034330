<template>
  <div class="flex align-center justify-between py-2 mb-4">
    <h1 class="text-4xl">Entities</h1>

    <button class="border bg-white border-black px-2 py-1 rounded transition-colors duration-300 hover:text-red-500" @click="() => openModal()">
      Add new
    </button>
  </div>

  <EntityFilter v-model="filter" />

  <EntityList :filter="filter" @item-click="openModal" />

  <BaseModal @close="onModalClose" ref="BaseModal">
    <EntityForm @cancel="$refs.BaseModal.close()" @saved="onItemSaved" ref="EntityForm" />
  </BaseModal>
</template>

<script>
import EntityForm from '@/components/Entity/EntityForm'
import EntityList from '@/components/Entity/EntityList'
import EntityFilter from '@/components/Entity/EntityFilter'

export default {
  name: 'Dashboard',
  components: {
    EntityFilter,
    EntityList,
    EntityForm
  },
  data () {
    return {
      filter: {
        term: '',
        integrations: [],
        country: ''
      }
    }
  },
  methods: {
    async openModal (entity = null) {
      if (entity) {
        await this.$store.dispatch('entity/getEntityInfo', entity)
      } else {
        // this.$store.commit('entity/SET_ITEM')
        await this.$store.dispatch('entity/getApicBaseLibraries')
      }

      this.$refs.BaseModal.open()
    },
    closeModal () {
      this.$refs.BaseModal.close()

      setTimeout(() => {
        this.onModalClose()
      }, 200)
    },
    onModalClose () {
      this.$store.commit('entity/RESET')
    },
    onItemSaved () {
      this.$store.dispatch('entities/fetchEntities')
        .then(() => {
          this.closeModal()
        })
    }
  }
}
</script>
