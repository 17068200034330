import axios from 'axios'
import router from '@/router'

export default async function ({ url, method = 'get', data, headers = {} }) {
  return axios({
    url,
    method,
    data,
    headers
  })
    .then(res => res.data)
    .catch(e => {
      if (!e.response) {
        throw new Error('http: network error')
      }

      if ([401].includes(e.response.status) && router.currentRoute.name !== 'login') {
        router.push({ name: '401' })
      }

      throw e
    })
}
