<template>
  <div class="fixed flex-col inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-end sm:justify-start">
    <BaseNotificationItem v-for="item in items" :item="item" :key="item.id" />
  </div>
</template>

<script>
import BaseNotificationItem from '@/components/base/BaseNotificationItem'

export default {
  name: 'BaseNotifications',
  components: { BaseNotificationItem },
  computed: {
    items () {
      return this.$store.getters['notifications/list']
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s, transform .3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
