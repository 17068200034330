<template>
  <input :type="type"
         :value="modelValue"
         :id="(id || `form-item--${name}`)"
         @input="$emit('update:modelValue', $event.target.value)"
         class="mt-1 focus:ring-black focus:border-black block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
  />
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    id: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: String,
      required: true
    }
  }
}
</script>
