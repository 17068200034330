<template>
  <div class="flex mb-4">
    <div class="flex items-center mr-4">
      <svg class="w-4 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
      </svg>

      Filter
    </div>

    <BaseFormItem label="term" prop="term" class="mr-3">
      <BaseInput :model-value="modelValue.term" @update:modelValue="$emit('update:modelValue', {...modelValue, term: $event})" name="term" />
    </BaseFormItem>

    <BaseFormItem label="Integrations" prop="integrations" class="mr-3">
      <BaseSelect :model-value="modelValue.integrations" mode="multiple" :options="integrationOptions" @update:modelValue="$emit('update:modelValue', {...modelValue, integrations: $event})" name="integrations" />
    </BaseFormItem>

    <BaseFormItem label="Country" prop="country" class="mr-3">
      <CountrySelect :model-value="modelValue.country" @update:modelValue="$emit('update:modelValue', {...modelValue, country: $event})" />
    </BaseFormItem>
  </div>
</template>

<script>
import BaseFormItem from '@/components/base/BaseFormItem'
import BaseInput from '@/components/base/BaseInput'
import BaseSelect from '@/components/base/BaseSelect'
import { INTEGRATIONS } from '@/constants'
import CountrySelect from '@/components/page-components/CountrySelect'

export default {
  name: 'EntityFilter',
  components: { CountrySelect, BaseSelect, BaseInput, BaseFormItem },
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        term: '',
        country: ''
      })
    }
  },
  data () {
    return {
      integrationOptions: Object.keys(INTEGRATIONS).map(system => ({
        value: system,
        label: INTEGRATIONS[system]
      }))
    }
  }
}
</script>
