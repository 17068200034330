<template>
  <div class="flex flex-col">
    <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg">
        <table class="min-w-full">
          <tr>
            <th class="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
              Nr
            </th>
            <th class="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider cursor-pointer" @click="sort('id')">
              Entity name
              <SortCaret class="float-right" column="id" :sort-by="sortBy" />
            </th>
            <th class="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider cursor-pointer" @click="sort('country')">
              Country
              <SortCaret class="float-right" column="country" :sort-by="sortBy" />
            </th>
            <th class="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
              Integrations
            </th>
            <th class="px-4 py-2 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider"></th>
          </tr>
          <tr v-for="(entity, $index) in entities" :key="entity.id" :class="{'bg-gray-100': $index % 2}" class="border-b border-gray-200 hover:bg-gray-200 transition transition-colors duration-300">
            <td @click="$emit('item-click', entity)" class="px-4 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 cursor-pointer">{{ ++$index }}</td>
            <td @click="$emit('item-click', entity)" class="px-4 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 cursor-pointer">{{ entity.id }}</td>
            <td @click="$emit('item-click', entity)" class="px-4 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 cursor-pointer">{{ entity.country }}</td>
<!--            <td @click="$emit('item-click', entity)" class="px-4 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 cursor-pointer">{{ entity.name }}</td>-->
<!--            <td @click="$emit('item-click', entity)" class="px-4 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 cursor-pointer">{{ entity.endpoint }}</td>-->
            <td @click="$emit('item-click', entity)" class="px-4 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 cursor-pointer">
              <span v-for="system in entity.integrations" :key="system" class="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium leading-4 bg-gray-200 text-gray-800 m-1">
                {{ system }}
              </span>
            </td>
            <td class="px-4 py-2 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
              <button
                @click="confirmRemove(entity)"
                type="button"
                class="inline-flex justify-center items-center px-2 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:shadow-outline-blue text-black hover:text-red-500 transition ease-in-out duration-150"
              >
                <svg class="w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <EntityRemovalConfirm @removed="$store.dispatch('entities/fetchEntities')" ref="EntityRemovalConfirm" />
  </div>
</template>

<script>
import EntityRemovalConfirm from './EntityRemovalConfirm'
import SortCaret from '@/components/base/SortCaret'

export default {
  name: 'EntityList',
  components: { SortCaret, EntityRemovalConfirm },
  props: {
    filter: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      sortBy: ''
    }
  },
  computed: {
    entities () {
      return this.$store.getters['entities/list'](this.filter, this.sortBy)
    }
  },
  methods: {
    confirmRemove (entity) {
      this.$refs.EntityRemovalConfirm.open(entity)
    },
    sort (col) {
      const currentDirection = this.sortBy.split('|')[1]
      const direction = this.sortBy.includes(`${col}|`) ? (currentDirection === 'asc' ? 'desc' : 'asc') : 'asc'
      this.sortBy = `${col}|${direction}`
    }
  }
}
</script>
