const initialState = () => ({
  list: []
})

export const state = initialState()

export const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  ADD_ITEM (state, item) {
    state.list.push(item)
  },
  REMOVE_ITEM (state, id) {
    state.list = state.list.filter(item => item.id !== id)
  }
}

export const getters = {
  list: state => state.list
}

export const actions = {
  showNotification ({ commit }, { message = '', type = 'success', timeout = 5000 } = {}) {
    const id = new Date().getTime()

    commit('ADD_ITEM', {
      id,
      message,
      type
    })

    setTimeout(() => {
      commit('REMOVE_ITEM', id)
    }, timeout)
  }
}
