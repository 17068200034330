<template>
  <svg :class="classNames" class="w-5 text-red-500 transform transition duration-200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
  </svg>
</template>

<script>
const CLASS_NAMES = {
  INACTIVE: 'hidden',
  UP: 'rotate-180',
  DOWN: 'rotate-0'
}

export default {
  name: 'SortCaret',
  props: {
    column: {
      type: String,
      required: true
    },
    sortBy: {
      type: String
    }
  },
  computed: {
    classNames () {
      const [col, direction] = this.sortBy.split('|')

      if (this.column !== col) {
        return CLASS_NAMES.INACTIVE
      }

      return direction === 'asc' ? CLASS_NAMES.DOWN : CLASS_NAMES.UP
    }
  }
}
</script>
