<template>
  <BaseSelect :options="apicBaseLibraries" name="apicBaseLibraryId">
    <template v-slot:option="{option}">
      {{ option.label }}
    </template>
    <template v-slot:singlelabel="{value}">
      <div class="multiselect-single-label">
        {{ value.label }}
      </div>
    </template>
  </BaseSelect>
</template>

<script>

export default {
  name: 'ApicBaseLibrarySelect',
  props: {
    apicBaseLibraries: Array
  }
}
</script>
