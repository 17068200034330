<template>
  <Multiselect
    :id="(id || `form-item--${name}`)"
    :model-value="modelValue"
    :options="options"
    :mode="mode"
    searchable
    @change="$emit('update:modelValue', $event)"
    ref="multiselect"
  >
    <template v-slot:multiplelabel="{values}">
      <div class="multiselect-multiple-label overflow-x-auto w-full">
        <span v-for="{label, value} of values" :key="value" class="mr-1 py-1 px-2 bg-gray-200 rounded-md">{{ label }}</span>
      </div>
    </template>
    <template v-slot:option="{option, search}">
      <slot name="option" :option="option" :search="search" />
    </template>
    <template v-slot:singlelabel="{value}">
      <slot name="singlelabel" :value="value">
        <div class="multiselect-single-label">
          {{ value.label }}
        </div>
      </slot>
      <a v-if="value" @click.prevent="$refs.multiselect.clear" class="multiselect-clear"></a>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'

export default {
  name: 'BaseSelect',
  components: {
    Multiselect
  },
  props: {
    id: String,
    name: {
      type: String,
      required: true
    },
    modelValue: {
      type: [Number, String, Object],
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'single'
    }
  }
}
</script>

<style>
.multiselect {
  @apply text-sm;

  &-input {
    height: 38px;
    min-height: 38px;
    border: none;
  }

  &.is-open {
    .multiselect-input {
      @apply border-black text-sm;
    }
  }

  &-multiple-label,
  &-placeholder,
  &-single-label {
    @apply text-sm;
  }

  &-search {
    @apply shadow-sm border-gray-300 border border-solid rounded-md transition-colors duration-200;

    input {
      height: 38px;
    }
  }

  &-option {
    @apply transition-colors duration-200;

    &.is-selected {
      @apply bg-black;

      &:hover {
        @apply bg-red-500
      }

      &.is-pointed {
        @apply bg-black;

        &:hover {
          @apply bg-red-500
        }
      }
    }
  }

  &-clear {
    @apply rounded-r-md;
    width: 35px;
    right: 1px;
  }
}
</style>
