<template>
  <BaseModal ref="BaseModal">
    <template v-if="entity">
      <div class="flex items-start sm:items-center">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-500-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg class="h-6 w-6 text-red-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="mt-4 text-center pb-1 sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Remove "{{ entity.id }}"?
          </h3>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            type="button"
            @click="confirm"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-500 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Remove
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            type="button"
            @click="$refs.BaseModal.close()"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
      </div>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'EntityRemovalConfirm',
  data () {
    return {
      entity: null
    }
  },
  methods: {
    open (entity) {
      this.entity = entity
      this.$refs.BaseModal.open()
    },
    confirm () {
      this.$store.dispatch('entity/removeEntity', this.entity)
        .then(() => {
          this.$refs.BaseModal.close()
          this.$emit('removed')
        })
    }
  }
}
</script>
