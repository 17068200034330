import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations'

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new SentryIntegrations.Vue({
      Vue,
      attachProps: true
    })],
    environment: process.env.NODE_ENV,
    // enabled: process.env.NODE_ENV === 'production' && process.env.VUE_APP_TEST !== 'e2e',
    logErrors: true
  })
}
