<template>
  <BaseSelect :options="countries" name="country">
    <template v-slot:option="{option}">
      {{ option.displayName }}
    </template>
    <template v-slot:singlelabel="{value}">
      <div class="multiselect-single-label">
        {{ value.displayName }}
      </div>
    </template>
  </BaseSelect>
</template>

<script>
import countries from '@/data/countries.json'

/**
 * custom select wrapper to provide functionality to search country by code
 */
export default {
  name: 'CountrySelect',
  data () {
    return {
      countries: countries.map(country => ({
        value: country.value,
        label: `${country.value} | ${country.label}`,
        displayName: country.label
      }))
    }
  }
}
</script>
