<template>
  <router-view />
  <BaseNotifications />
</template>

<script>
import BaseNotifications from '@/components/base/BaseNotifications'

export default {
  components: { BaseNotifications }
}
</script>
