<template>
  <label
    class="-ml-px flex flex-grow items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150"
    :class="{'bg-gray-200 cursor-not-allowed': disabled}">
    <input type="checkbox" :disabled="disabled" :checked="checked" class="focus:ring-red-500 h-4 w-4 text-red-500 border-gray-300 rounded mr-2" />
    <slot />
  </label>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    checked: Boolean,
    disabled: Boolean
  }
}
</script>
